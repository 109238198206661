.ContactForm {
    background-color: rgba(38, 73, 70, 0.8);
    width: 100%;
    font-family: 'Overpass', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ContactForm #form-title {
    color: rgba(245, 173, 88, 1);
    font-size: 2em;
    margin: 0 auto;
}

.ContactForm form {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 20px 72px;
    max-width: 1000px;
}

.ContactForm input, #description {
    margin: 5px;
    padding: 2px;
    border-radius: 3px;
}

.ContactForm input, textarea::-webkit-input-placeholder {
    font-family: 'Overpass', sans-serif;
    font-size: 1em;
}

.ContactForm #name-email {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ContactForm #phone-location {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ContactForm #name, #email {
    width: 100%;
}

.ContactForm #description {
    width: 100%;
}

.ContactForm .button {
    color: rgba(245, 173, 88, 1);
    border: 1px solid rgba(219, 211, 165, 0);
    background-color: rgba(0, 0, 0, 0.3);
    margin: 10px auto;
    height: 2em;
    width: 5em;
    font-size: 1em;
    
}

.ContactForm .button:hover {
  color: rgba(245, 173, 88, 1);
  border: 1px solid rgba(245, 173, 88, 1);
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 450px) {
    .ContactForm #name-email {
        flex-direction: column;
    }
    
    .ContactForm #phone-location {
        flex-direction: column;
    }
    
  }