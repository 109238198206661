.Project {
    max-width: 100vw;
    margin: 0 auto;
    background-color: rgba(38, 73, 70, 1);
    color: rgba(219, 211, 165, 1);
}

.Project header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    z-index: 1;
    background-color: rgba(38, 73, 70, 1);
}
  
.Project .main {
    display: flex;
}

.Project .content {
    width: 28vw;
    margin-left: 16px;
}

.Project .projectName {
    margin-top: 0;
    font-weight: 500;
}

.Project .projectLocation {
    font-weight: normal;
}


.Project .projectYear {
    font-weight: normal;
}


.Project .projectType {
    font-weight: normal;
}

.Project .projectDescription {
    text-align: justify;
}
  
.carousel-root {
    margin-left: 45px;
}
