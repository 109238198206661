#header {
  text-align: center;
  width: 100%;
  padding: 50px 0;
  margin-bottom: 20px;
  height: 350px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  /* align-items: center; */
}

.parallax {
  background-image: url('../assets/home-big-pic.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.logo {
  max-width: 250px;
  max-height: 250px;
  /* margin: 16px auto; */
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.logoImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 350px;
  position: absolute;
}

#header > .Menu {
  display: flex;
  flex-direction: column;
  min-width: 25vw;
  margin-left: auto;
  margin-right: 0;
}

#header > .Menu > * {
  /* color: rgba(219, 211, 165, 1); */
  color: white;
  padding: 5px;
  font-family: 'Red Hat Text', sans-serif;
  text-align: right;
  margin-right: 40px;
  font-size: large;
  text-decoration: none;
  position: relative;
}

#header > .Menu > *:hover {
  color: rgba(245, 173, 88, 1);
  font-size: x-large;
  white-space: nowrap;
  font-weight: bold;
}

#about {
  background-color: rgba(38, 73, 70, 0.8);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 72px 0;
}

#about * {
  width: 60vw;
  margin: auto;
  font-family: 'Overpass', sans-serif;
}

#about .title {
  color: rgba(245, 173, 88, 1);
  font-size: 0.8em;
  font-weight: 600;
  padding: 8px 0;
}

#about .subtitle {
  color: white;
  font-size: 2em;
  letter-spacing: -0.5px;
  padding-bottom: 24px;
}

#about .body {
  color: lightgray;
  text-align: justify;
}

#about .services {
  color: white;
  font-size: 1.5em;
  text-align: center;
  letter-spacing: -0.5px;
  padding-bottom: 16px;
}

#services {
  text-align: left;
  width: 50vw;
  font-size: 1em;
  color: white;
  line-height: 1.8em;
}

.tab {
  padding-left: 40px;
}

#news {
  /* display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 0; */
  height: 300px;
}

/* @media screen and (max-width: 1000px) {
  #header {
    padding: 40px 0;
  }

  #about * {
    width: 90vw;
  }
}

@media screen and (max-width: 850px) {
  #header {
    padding: 24px 0;
  }

  .logo {
    max-width: 250px;
  }

  #header > .Menu {
    margin: 24px auto 0;
  }
}

@media screen and (max-width: 685px) {
  #header {
    padding: 16px 0;
  }

  .logo {
    max-width: 200px;
  }

  #header > .Menu {
    margin: 12px auto 0;
  }

  #header > .Menu > * {
    padding: 6px;
  }

  #news {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  /* .logo {
     margin-top: 32px; 
  } 

  #header > .Menu {
    flex-direction: column;
  }

  #header > .Menu > * {
    padding: 24px;
  }
} */
