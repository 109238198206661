.Projects .projects-grid {
  background: rgba(38, 73, 70, 1);
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
}

body {
  background: rgba(38, 73, 70, 1);
}

.Projects .projects-grid-item {
  display: flex;
  padding: 0;
  margin: 0;
  flex: 0 0 25%;
  width: 25vw;
  height: 25vw;
}

.Projects .projects-grid-item a {
  text-decoration: none;
  color: rgba(219, 211, 165, 1);
}

.Projects .project-container {
  display: flex;
  padding: 0;
  margin: 0;
  /* flex: 0 0 calc(25vw - 1px); */
  width: 100%;
  /* height: calc(25vw - 1px); */
}

@media screen and (max-width: 991px) {
  .Projects .projects-grid-item {
    flex: 0 0 33%;
    width: 33.33333333333vw;
    height: 33.33333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .Projects .projects-grid-item {
    flex: 0 0 50%;
    width: 50vw;
    height: 50vw;
  }
}

@media screen and (max-width: 500px) {
  .Projects .names {
    font-size: 1.2em !important;
  }
}

.Projects .project {
  width: 100%;
}

.Projects .project-img {
  width: 100%;
  height: 100%;
}

.Projects .imgs {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

.Projects .names-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.Projects .names {
  font-size: 1.5em;
  font-weight: 500;
  text-transform: none;
  text-align: center;
  line-height: 1.1;
}

@media screen and (max-width: 458px) {
  .Projects .container {
    flex-basis: 33%;
  }
}

@media screen and (max-width: 376px) {
  .Projects .container {
    flex-basis: 50%;
  }
}
